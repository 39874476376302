import axios from "axios";
import { CreatePayload } from "../Pages/AddBeneficiaryMinDetails/Action/createBenficiaries";
import apiConfig from "../apiConfig/apiConfig";

console.log(apiConfig);
const baseUri = apiConfig.api.baseURL || "https://v3.apis.pococare.com";
const urls = {
  USER_URI: `${baseUri}/auth/api/v1`,
  BEN_URI: `${baseUri}/beneficiary/api/v1`,
  AZURE_URI: `${baseUri}/azure/api/v1`,
  TICKET_URI: `${baseUri}/ticket/api/v1`,
  HOSPITAL_URI: `${baseUri}/hospital/api/v1/hospital`,
  COMPANY_URI: `${baseUri}/company/api/v1/company-service/partnerCompanies`,
};
const axiosInstance = axios.create({
  baseURL: "",
  withCredentials: true,
  headers: {
    "content-type": "application/json",
    Accept: "application/json",
  },
});

//beneficiary

export const getAllBeneficiariesData = (subId: string) =>
  axiosInstance.get(
    `${urls.BEN_URI}/beneficiary/find-by-subscriber-id/${subId}`
  );
export const updateBenAddressById = (benId: string, payload: any) =>
  axiosInstance.patch(
    `${urls.BEN_URI}/beneficiary/address-service/update-address/${benId}`,
    payload
  );
export const addBeneficiaries = (payload: CreatePayload) =>
  axiosInstance.post(
    `${urls.BEN_URI}/beneficiary/create-many-beneficiary`,
    payload
  );
export const getBeneficiaryByUserId = (id: string) =>
  axiosInstance.get(`${urls.BEN_URI}/beneficiary/find-by-id/${id}`);
export const getSingleBenData = (benId: string) =>
  axiosInstance.get(`${urls.BEN_URI}/beneficiary/find-by-id/${benId}`);
export const updateBeneficiaryPersonalInfo = (benId: string, payload: any) =>
  axiosInstance.patch(
    `${urls.BEN_URI}/beneficiary/personal-info/update-personal-info/${benId}`,
    payload
  );

export const getSubscriberById = (id: string) =>
  axiosInstance.get(
    `${urls.BEN_URI}/subscriber/${id}`
  );



// temporary beneficiary ;
export const activateTempBen = (payload: any) =>
  axiosInstance.patch(`${urls.BEN_URI}/temp-ben/${payload.benId}`, payload);


export const signupForHospital = (payload: any) =>
  axiosInstance.post(`${urls.BEN_URI}/temp-ben/signup`, payload);

// Beneficiary Address
export const deleteBeneficiaryAddress = (benId: string, addressId: string) =>
  axiosInstance.patch(
    `${urls.BEN_URI}/beneficiary/address-service/delete-address/${benId}`,
    { ID: addressId }
  );
export const addBeneficiaryAddress = (benId: string, payload: any) =>
  axiosInstance.patch(
    `${urls.BEN_URI}/beneficiary/address-service/create-address/${benId}`,
    payload
  );
export const updateBeneficiaryAddress = (benId: string, payload: any) =>
  axiosInstance.patch(
    `${urls.BEN_URI}/beneficiary/address-service/update-address/${benId}`,
    payload
  );

// Beneficiary Emergency Contact
export const addBeneficiaryEmergencyContact = (benId: string, payload: any) =>
  axiosInstance.patch(
    `${urls.BEN_URI}/beneficiary/emergency-contacts/${benId}`,
    payload
  );
export const deleteBeneficiaryEmergencyContact = (
  benId: string,
  payload: any
) =>
  axiosInstance.patch(
    `${urls.BEN_URI}/beneficiary/emergency-contacts/delete-emergency-contact/${benId}`,
    payload
  );
export const updateBeneficiaryEmergencyContact = (
  benId: string,
  payload: any
) =>
  axiosInstance.patch(
    `${urls.BEN_URI}/beneficiary/emergency-contacts/update-emergency-contact/${benId}`,
    payload
  );

// Beneficiary Insurance

export const addBeneficiaryInsurance = (benId: string, payload: any) =>
  axiosInstance.patch(
    `${urls.BEN_URI}/beneficiary/insurance-policies-service/create-insurance-policy/${benId}`,
    payload
  );

export const deleteBeneficiaryInsurance = (benId: string, policyId: string) =>
  axiosInstance.patch(
    `${urls.BEN_URI}/beneficiary/insurance-policies-service/delete-insurance-policy/${benId}/policyId/${policyId}`
  );
export const updateBeneficiaryInsurance = (benId: string, payload: any) =>
  axiosInstance.patch(
    `${urls.BEN_URI}/beneficiary/insurance-policies-service/update-insurance-policy/${benId}`,
    payload
  );

// Beneficiary ContactUs
export const contactUs = (payload: any) =>
  axiosInstance.post(`${urls.BEN_URI}/contactus`, payload);

// Consent Management 
export const saveConsentInfo = (payload: any) => axiosInstance.post(`${urls.USER_URI}/terms-condition-consent`, payload);

export const checkConsentStatus = (benId: any) => axiosInstance.get(`${urls.USER_URI}/terms-condition-consent/${benId}`);

// Hospital
export const getNearByHospital = (payload: any) =>
  axiosInstance.post(`${urls.HOSPITAL_URI}/nearby`, payload);
export const createHospital = (payload: any) =>
  axiosInstance.post(`${urls.HOSPITAL_URI}`, payload);
export const getHospitalById = (id: string) =>
  axiosInstance.get(`${urls.HOSPITAL_URI}/${id}`);

//AUTH SERVICE
export const sendOtpReq = (payload: any) =>
  axiosInstance.post(`${urls.USER_URI}/auth/send-otp`, payload);
export const verifyOtp = (data: any) =>
  axiosInstance.post(`${urls.USER_URI}/auth/login-with-otp`, data);
export const logOutUser = () =>
  axiosInstance.get(`${urls.USER_URI}/auth/logout`);
export const updateUserMobileNumber = (userId: string, payload: any) =>
  axiosInstance.patch(
    `${urls.USER_URI}/user/add-mobile-number/${userId}`,
    payload
  );
export const sendOtpForActivation = (payload: any) =>
  axiosInstance.post(
    `${urls.USER_URI}/auth/send-otp-unregisterd-user`,
    payload
  );
export const verifyOtpForActivation = (payload: any) =>
  axiosInstance.post(
    `${urls.USER_URI}/auth/verify-otp-unregistered-user`,
    payload
  );
export const getUserById = (payload: any) =>
  axiosInstance.get(
    `${urls.USER_URI}/user/${payload.id}`
  );

// medical info
export const saveMedicalInfo = (benId: string, payload: any) =>
  axiosInstance.patch(
    `${urls.BEN_URI}/beneficiary/medical-info-record/${benId}`,
    payload
  );
export const getMedicalInfo = (benId: string) =>
  axiosInstance.get(`${urls.BEN_URI}/beneficiary/medical-info-record/${benId}`);
//azure
export const getAzureSignedUrl = (
  docName: string,
  benId: string,
  docType: string
) =>
  axiosInstance.post(`${urls.AZURE_URI}/signedUrl/generateSignedUrl`, {
    docName,
    benId,
    docType,
  });


export const getGMapAddress = (
  address1: string,
  address2: string,
  city: string,
  state: string,
  pincode: string
) =>
  axiosInstance.get(`${urls.AZURE_URI}/google-map?address1=${address1}&address2=${address2}&city=${city}&state=${state}&pincode=${pincode}`);

export const generateSasToken = () => {
  return axiosInstance.get(`${urls.AZURE_URI}/signedUrl/regenerateSaSToken`, {
  })
}

//Ticket service
export const raisedEmergencyWithCall = (
  benId: string,
  mobile: string,
  lat: string | null,
  lng: string | null
) =>
  axiosInstance.get(
    `${urls.TICKET_URI}/qr/${benId}/${mobile}/${lat}/${lng}/em`
  );
export const raisedEmergencyWithQrWithMobile = (
  benId: string,
  mobile: string,
  lat: string | null,
  lng: string | null
) =>
  axiosInstance.get(
    `${urls.TICKET_URI}/qr/${benId}/${mobile}/${lat}/${lng}/qr`
  );
export const raisedEmergencyWithQR = (
  company: string,
  lat: string | null,
  lng: string | null
) =>
  axiosInstance.get(
    `${urls.TICKET_URI}/qr-noMobile/${company}/${lat}/${lng}/qr`
  );
export const raiseEmergencyWithCompanyId = (
  company: string,
  mobile: string,
  lat: string | null,
  lng: string | null
) =>
  axiosInstance.post(
    `${urls.TICKET_URI}/company-qr/${company}/${mobile}/${lat}/${lng}/companyQr`
  );

//Company Service
export const getCompanyByName = (company: string) =>
  axiosInstance.get(`${urls.COMPANY_URI}/${company}`);

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest &&
      !originalRequest._isRetry
    ) {
      originalRequest.isRetry = true;
      try {
        await axios.get(`${urls.USER_URI}/auth/refresh-token`, {
          withCredentials: true,
        });
        return axiosInstance.request(originalRequest);
      } catch (err) {
        console.log(err);
        // localStorage.clear();
        localStorage.removeItem("beneficiariesData");
        localStorage.removeItem("userData");
        localStorage.removeItem("persist:MainData");
        window.location.reload();
      }
      localStorage.clear();
      localStorage.removeItem("beneficiariesData");
      localStorage.removeItem("userData");
      localStorage.removeItem("persist:MainData");
      window.location.reload();
    }
    throw error;
  }
);
export default axiosInstance;
